export const getPermutations = (arr, size) => {
    if (size === 0) return [[]];
    if (arr.length === 0) return [];

    let result = new Set();

    for (let i = 0; i < arr.length; i++) {
        let remaining = arr.slice(0, i).concat(arr.slice(i + 1));
        let remainingPermutations = getPermutations(remaining, size - 1);

        for (let permutation of remainingPermutations) {
            let sortedPermutation = [arr[i], ...permutation].sort((a, b) => a - b);
            result.add(JSON.stringify(sortedPermutation));
        }
    }

    return Array.from(result).map(JSON.parse);
};

export const canAchieveTarget = (currentSet, target, used = new Set(), originalInputs = []) => {
    if (target === 0 && used.size > 0) return true;

    if (originalInputs.length !== 3) {
        const unique = new Set(currentSet);
        if (currentSet.length !== unique.size) return false;
    }

    for (let i = 0; i < currentSet.length; i++) {
        if (used.has(i)) continue;

        const num = currentSet[i];
        used.add(i);

        if (canAchieveTarget(currentSet, target - num, used) ||
            canAchieveTarget(currentSet, target + num, used)) {
            return true;
        }

        used.delete(i);
    }
    return false;
};

export const canGenerateAllTargets = (numberSet, targets) => {
    for (const target of targets) {
        if (!canAchieveTarget(numberSet, target, new Set())) {
            return false;
        }
    }
    return true;
};